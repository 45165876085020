import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import {pushCandidateEvent, useDataLayer} from "dataLayerHelper";
import ListItem from "@Components/ListItem";
import Modal from "@Components/Modal";
import AutomatedSystemParagraph from "@Components/AutomatedSystemParagraph";
import DeleteAccountParagraph from "@Components/DeleteAccountParagraph";
import {PersonalDataParagraph, DefaultDsarValues} from "@Components/PersonalDataParagraph";
import Button from "@FormElements/Button";
import Form from 'form';
import FormContext from "formContext";
import axios from "axios";
import SubMenu from "@Components/SubMenu";
import Logger from '@ffw/logger-lib';

function Settings({intl, staticContext, match}) {
  const {formName, setFormName, formData, setFormData, handleErrors, currentStep, getFormSchema, showToast, setToastMessage, setErrors, handleChange, additionalData, setAdditionalData, timesRendered, modalOpen, setModalOpen, setButtonLoading} = useContext(FormContext);
  const {locale} = intl;
  const [loading, setLoading] = useState(false);
  const [dsarSuccess, setDsarSuccess] = useState(0);
  const {user, dsarSettings} = additionalData;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user-data?currentLanguage=${locale}`)
        .then(response => {
          if (response.data.redirect === true && response.data.location) {
            window.location.href = response.data.location;
          }
          else {
            setAdditionalData(prevState => ({
              ...prevState,
              ...response.data
            }));
          }
        });
    }
  }, [timesRendered]);

  const handleEditPassword = () => {
    setFormData([]);
    setFormName('edit-password');
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setFormData([]);
    setFormName('');
    setModalOpen(false);
    setErrors();
    setButtonLoading(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_PREFIX}/change-password`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setErrors();
          setLoading(false)
          setTimeout(() => {
            const message = intl.formatMessage({id: 'AccountSettings.Password.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
        }
      })
      .catch(error => {
        setLoading(false)
        handleErrors(error.response.data);
      });
  };

  const handleAccountDeleteSubmit = event => {
    event.preventDefault();
    setLoading(true);
    const {target} = event;
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user`)
      .then(() => {
        pushCandidateEvent('delete');
        // If we have a prefixed url with language - redirect to that language.
        if (locale === window.location.pathname.split('/')[1]) {
          window.location = `/${locale}/#toast=delete-account`;
        }
        else {
          window.location = "/#toast=delete-account";
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          const modal = target && target.closest(".modal");
          setLoading(false);
          modal.querySelectorAll("button.button").forEach(ele => {
            ele.parentNode.removeChild(ele);
          });
          modal.querySelectorAll("p").forEach(ele => {
            ele.textContent = intl.formatMessage({id: 'AccountSettings.DeleteAccount.UnableToRemoveMessage'});
          });
        }
        else {
          // Something went wrong.
          Logger.error(error, "account",{
            "context": 'Unexpected error'
          });
        }
      });
  };

  const handlePersonalDataSubmit = event => {
    event.preventDefault();
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_PREFIX}/dsar`, {
      name: user.first_name,
      email: user.email
    })
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            setModalOpen(false);
            setFormData([]);
            setErrors();
            setLoading(false)
            setButtonLoading(false);
            setDsarSuccess((dsarSuccess) => 1);
          }
          else {
            setModalOpen(false);
            setLoading(false);
            setButtonLoading(false);
            setDsarSuccess((dsarSuccess) => -1);
          }
        })
        .catch(error => {
            setModalOpen(false);
            setLoading(false);
            setButtonLoading(false);
            setDsarSuccess((dsarSuccess) => -1);
            handleErrors(error.response.data);
        });
  };

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleSubmit} settings={modalButtonSettings} />
  }

  const modalFooterDeleteAccount = () => {
    return (
      <>
        <div className="button-group button-group--full-width hidden--from-l">
          <Button
            onClick={handleAccountDeleteSubmit}
            className="button button--full-width button--filled behat-yes-delete"
            settings={{
              text: "AccountSettings.DeleteAccount.Modal.Button.Text",
              withWrapperDiv: false,
            }}
          />
          <Button
            onClick={onCloseModal}
            className="button button--full-width button--plain"
            settings={{
              text: "Utils.Cancel",
              withWrapperDiv: false,
            }}
          />
        </div>
        <div className="button-group--options button-group hidden--until-l">
          <Button
            onClick={handleAccountDeleteSubmit}
            className="button button--m button--filled behat-yes-delete"
            settings={{
              text: "AccountSettings.DeleteAccount.Modal.Button.Text",
              withWrapperDiv: false,
            }}
          />
          <Button
            onClick={onCloseModal}
            className="button button--m button--plain"
            settings={{
              text: "Utils.Cancel",
              withWrapperDiv: false,
            }}
          />
        </div>
      </>
    );
  }

  const modalFooterPersonalData = () => {
  const modalButton = (dsarSettings['confirmation_popup_button_label'] !== undefined && dsarSettings['confirmation_popup_button_label']) ?
      dsarSettings['confirmation_popup_button_label'] :
      DefaultDsarValues['AccountSettings.PersonalData.Modal.Button.Text'];
    return (
      <>
        <div className="button-group button-group--full-width hidden--from-l">
          <Button
            onClick={handlePersonalDataSubmit}
            className="button button--full-width button--filled behat-dsar-request"
            settings={{
              text: modalButton,
              withWrapperDiv: false,
              staticText: false,
            }}
          />
          <Button
            onClick={onCloseModal}
            className="button button--full-width button--plain behat-dsar-quit"
            settings={{
              text: "Utils.Cancel",
              withWrapperDiv: false,
            }}
          />
        </div>
        <div className="button-group--options button-group hidden--until-l">
          <Button
            onClick={handlePersonalDataSubmit}
            className="button button--m button--filled behat-dsar-request"
            settings={{
              text: modalButton,
              withWrapperDiv: false,
              staticText: false,
            }}
          />
          <Button
            onClick={onCloseModal}
            className="button button--m button--plain behat-dsar-quit"
            settings={{
              text: "Utils.Cancel",
              withWrapperDiv: false,
            }}
          />
        </div>
      </>
    );
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    if (formName === 'account-delete') {
      return (
        <Modal
          title={intl.formatMessage({id: 'AccountSettings.DeleteAccount.Modal.Title'})}
          onClose={onCloseModal}
          footer={modalFooterDeleteAccount()}
          footerDivider={false}
          footerDividerOnly={true}
          modalOverflow={false}
        >
          <p>{intl.formatMessage({id: 'AccountSettings.DeleteAccount.Modal.Text'})}</p>
        </Modal>
      );
    }
    if (formName === 'personal-data') {
        const modalTitle = (dsarSettings['confirmation_popup_title'] !== undefined && dsarSettings['confirmation_popup_title']) ?
            dsarSettings['confirmation_popup_title'] :
            DefaultDsarValues['AccountSettings.PersonalData.Modal.Title'];
        const modalText = (dsarSettings['confirmation_popup_text'] !== undefined && dsarSettings['confirmation_popup_text']['value']) ?
            dsarSettings['confirmation_popup_text']['value'] :
            DefaultDsarValues['AccountSettings.PersonalData.Modal.Text'];
      return (
        <Modal
          title={modalTitle}
          onClose={onCloseModal}
          footer={modalFooterPersonalData()}
          footerDivider={false}
          footerDividerOnly={true}
          modalOverflow={false}
        >
            <div dangerouslySetInnerHTML={{__html: modalText}} />
        </Modal>
      );
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Settings'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header bg-variant-white header--s header--welcome"/>
      </Helmet>
      <SubMenu />
      <div className="block__wrapper wrapper pt-l l:pt-xl">
        <div className="block__header">
          <h2 className="block__title">{intl.formatMessage({id: 'AccountSettings.Password.Section.Title'})}</h2>
        </div>
        <div className="block__content block__content--align-right block__content--s">
          <ListItem
            title={intl.formatMessage({id: 'AccountSettings.Password.List.Title'})}
            handleEdit={handleEditPassword}
            label={intl.formatMessage({id: 'Button.Edit.Title'})}
            divider="true"
          >
            <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
              <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'AccountSettings.Password.List.Title'})}</div>
              <div className="my-environment-item__list-content mt-xxs l:mt-none">**********</div>
            </div>
          </ListItem>
          {process.env.REACT_APP_SECTION_AUTOMATED_SYSTEM_PARAGRAPH === "true" &&
            <ListItem title={intl.formatMessage({id: 'AccountSettings.AutomatedSystems.Title'})} divider="true">
              <AutomatedSystemParagraph />
            </ListItem>
          }
          <ListItem
          >
            <div id="dsar">
              {process.env.REACT_APP_DSAR_REQUEST === "true" &&
                <PersonalDataParagraph requestOff={dsarSuccess} dsarSettings={dsarSettings} />
              }
            </div>
          </ListItem>
          {process.env.REACT_APP_SECTION_DELETE_ACCOUNT_PARAGRAPH === "true" &&
            <ListItem title={intl.formatMessage({id: 'AccountSettings.DeleteAccount.Title'})}>
              <DeleteAccountParagraph />
            </ListItem>}
        </div>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(Settings);
